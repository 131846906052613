import { motion, MotionStyle } from 'framer-motion';
import { wheelImage } from '../../App/Scenes/Boost/styles';
import { boost_logo_duration, spin_duration } from '../../App/Scenes/Boost';
import { text_duration, wheel_appear_duration } from '../../App/Scenes/Boost';
import { default_delay } from '../../App/Scenes/Boost';

const BoostWheelSvg = ({
  degrees,
}: {
  degrees: number;
}): React.ReactElement => {
  return (
    <motion.svg
      style={{
        ...(wheelImage as MotionStyle),
        willChange: 'scale, rotateZ',
        backfaceVisibility: 'hidden',
      }}
      initial={{
        rotate: 1, // work around to force font rendering
        rotateZ: 0,
      }}
      animate={{
        rotate: 0, // work around to force font rendering
        rotateZ: degrees,
      }}
      transition={{
        rotate: {
          delay: default_delay + boost_logo_duration + text_duration - 0.1,
          duration: wheel_appear_duration + 0.1,
        },
        rotateZ: {
          delay:
            default_delay +
            boost_logo_duration +
            text_duration +
            wheel_appear_duration,
          duration: spin_duration,
          ease: [0.2, 1.1, 0.7, 1],
        },
      }}
      id="boost-wheel-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="555"
      height="555"
      viewBox="0 0 555 555"
    >
      <g
        id="Groupe_41833"
        data-name="Groupe 41833"
        transform="translate(6966 7101)"
      >
        <path
          id="Tracé_16124"
          data-name="Tracé 16124"
          d="M275.81,0c152.326,0,275.81,123.485,275.81,275.812S428.136,551.623,275.81,551.623,0,428.138,0,275.812,123.485,0,275.81,0Z"
          transform="translate(-6963 -7099)"
          fill="#fff"
        />
        <g id="Groupe_41832" data-name="Groupe 41832">
          <g id="Groupe_41825" data-name="Groupe 41825">
            <path
              id="Tracé_16123"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#ffbc50"
            />
            <g
              id="Groupe_41824"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x2"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-20" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  2
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41826"
            data-name="Groupe 41826"
            transform="translate(-7852.18 2656.56) rotate(51.42)"
          >
            <path
              id="Tracé_16123-2"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#ff8240"
            />
            <g
              id="Groupe_41824-2"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-2"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x4"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-20" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  4
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41827"
            data-name="Groupe 41827"
            transform="translate(-14825.429 -1824.095) rotate(102.84)"
          >
            <path
              id="Tracé_16123-3"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#fded4f"
            />
            <g
              id="Groupe_41824-3"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-3"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x1"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-20" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  1
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41828"
            data-name="Groupe 41828"
            transform="translate(-15671.224 -10068.038) rotate(154.26)"
          >
            <path
              id="Tracé_16123-4"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#e23634"
            />
            <g
              id="Groupe_41824-4"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-4"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x10"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-31" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  10
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41829"
            data-name="Groupe 41829"
            transform="translate(-9754.038 -15871.561) rotate(-154.32)"
          >
            <path
              id="Tracé_16123-5"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#ffdd27"
            />
            <g
              id="Groupe_41824-5"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-5"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x1.5"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-36" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  1.5
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41830"
            data-name="Groupe 41830"
            transform="translate(-1526.413 -14863.84) rotate(-102.9)"
          >
            <path
              id="Tracé_16123-6"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#ff9638"
            />
            <g
              id="Groupe_41824-6"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-6"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x3"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-20" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  3
                </tspan>
              </text>
            </g>
          </g>
          <g
            id="Groupe_41831"
            data-name="Groupe 41831"
            transform="matrix(0.623, -0.782, 0.782, 0.623, 2816, -7800.5)"
          >
            <path
              id="Tracé_16123-7"
              data-name="Tracé 16123"
              d="M360.818,26.046a260.054,260.054,0,0,0-224.882.038L248.417,259.945Z"
              transform="translate(-6933.653 -7083.099)"
              fill="#fc5151"
            />
            <g
              id="Groupe_41824-7"
              data-name="Groupe 41824"
              transform="translate(-6736.717 -7052.599)"
            >
              <rect
                id="Rectangle_8463-7"
                data-name="Rectangle 8463"
                width="102.064"
                height="60.376"
                rx="30.188"
                transform="translate(0 0)"
                fill="#fff"
              />
              <text
                id="x3"
                transform="translate(50.634 43.023)"
                fontSize="32"
                fontFamily="Nunito-ExtraBold"
                fontWeight="800"
              >
                <tspan x="-20" y="0" fontFamily="Nunito-ExtraBold">
                  x
                </tspan>
                <tspan y="0" fontSize="38" fontFamily="Nunito-ExtraBold">
                  5
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <circle
          id="Ellipse_4775"
          data-name="Ellipse 4775"
          cx="277"
          cy="277"
          r="115"
          transform="translate(-6966 -7101)"
          fill="#fff"
        />
      </g>
    </motion.svg>
  );
};

export default BoostWheelSvg;
