// Ref at https://easings.net/
export const easeOutCirc = (x: number): number => {
  return Math.sqrt(1 - Math.pow(x - 1, 2));
};

export const easeOutQuad = (x: number): number => {
  return 1 - (1 - x) * (1 - x);
};

export const easeOutQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 5);
};
export const easeOutExpo = (x: number): number => {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
};

export const easeOutBack = (x: number): number => {
  const c1 = 1.70158;
  const c3 = c1 + 1;

  return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
};

export const easeInOutBack = (x: number): number => {
  return Math.pow(1.78 - (21 / 10) * x, 2) + 0.9;
};

export const easeInCubic = (x: number): number => {
  return x * x * x;
};
export const easeOutCubic = (x: number): number => {
  return 1 - Math.pow(1 - x, 1.2);
};

export const easeOutSine = (x: number): number => {
  return x;
};

export const customEaseOut = (x: number): number => {
  if (x <= 0.4) {
    return Math.pow(x * 1.25, 3) * 6 + 0.2;
  } else if (x <= 0.85) {
    return (Math.pow(x + 1.2, 2) * 1) / 10 + 0.45;
  }
  const t = (x - 0.85) * 1;
  return Math.pow(t + x + 1.2, 2) / 10 + 0.45;
};

export const ONE_SECOND = 1000;
export const ONE_ROUND = 2 * Math.PI;
