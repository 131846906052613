import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { motion, useAnimation } from 'framer-motion';
import { MetaText } from '../../../Common/MetaText';
import { getMeta } from '../../../../helpers/getMeta';
import { useEffect } from 'react';
import { createScene } from '../../../../helpers/createScene';

interface IBContent extends IBaseMeta {
  middleText?: Texts;
  topText?: Texts;
}

const AttentionHTML = (props: IStateProps) => {
  const meta = getMeta(props);
  return (
    <>
      <DisplayAnimation {...meta} />
      <LotoExpressLogo {...props} />
    </>
  );
};

export const AttentionScene = createScene(AttentionHTML, null);

const DisplayAnimation = ({ ...meta }: IBContent) => {
  const controls = useAnimation();
  const textVariants = {
    initial: (custom: string) => ({
      translateX:
        custom === 'top' ? '-120vw' : custom === 'middle' ? '120vw' : '0',
      translateY: custom === 'bottom' ? '60vh' : '0',
    }),
    animate: {
      translateX: '0',
      translateY: '0',
      transition: { duration: 2, type: 'spring', bounce: 0.15 },
    },
    scaleDown: (custom: string) => ({
      translateX: '0',
      translateY:
        custom === 'bottom-scale'
          ? '-100%'
          : custom === 'middle-scale'
            ? '0%'
            : '100%',
      scale: 0,
      transition: { duration: 1.8, ease: 'easeInOut' },
    }),
  };
  const handleAnimationComplete = () => {
    controls.start('scaleDown');
  };

  useEffect(() => {
    return () => {
      controls?.stop();
    };
  }, [controls]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <motion.div
        style={{ willChange: 'translateX, translateY, scale' }}
        custom="top"
        initial="initial"
        animate="animate"
        variants={textVariants}
        onAnimationComplete={handleAnimationComplete}
      >
        <motion.div
          style={{ willChange: 'translateX, translateY, scale' }}
          custom="top-scale"
          initial="initial"
          animate={controls}
          variants={textVariants}
        >
          <MetaText content={meta?.topText?.[0]} />
        </motion.div>
      </motion.div>

      <motion.div
        style={{ willChange: 'translateX, translateY, scale' }}
        custom="middle"
        initial="initial"
        animate="animate"
        variants={textVariants}
      >
        <motion.div
          style={{ willChange: 'translateX, translateY, scale' }}
          custom="middle-scale"
          initial="initial"
          animate={controls}
          variants={textVariants}
        >
          <MetaText content={meta?.middleText?.[0]} />
        </motion.div>
      </motion.div>

      <motion.div
        style={{ willChange: 'translateX, translateY, scale' }}
        custom="bottom"
        initial="initial"
        animate="animate"
        variants={textVariants}
      >
        <motion.div
          style={{ willChange: 'translateX, translateY, scale' }}
          custom="bottom-scale"
          initial="initial"
          animate={controls}
          variants={textVariants}
        >
          <MetaText content={meta?.bottomText?.[0]} />
        </motion.div>
      </motion.div>
    </div>
  );
};
