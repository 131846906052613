import yellowSplash from '../../../assets/yellowSplash.svg';
import { IBaseMeta } from '../../../interfaces/sceneState';
import extra from '../../../assets/extra.svg';

const boostContainerStyle = (bottomOffset: boolean) => {
  return {
    position: 'absolute',
    bottom: bottomOffset ? '89px' : '0px',
    right: '0px',
  } as React.CSSProperties;
};
const ImgSplashStyle: React.CSSProperties = {
  position: 'relative',
  bottom: '0px',
  right: '0px',
  maxHeight: '100%',
  maxWidth: '100%',
};
const ImgBoostStyle: React.CSSProperties = {
  position: 'relative',
  bottom: '15px',
  right: '25px',
  maxHeight: '100%',
  maxWidth: '100%',
  zIndex: 1,
  scale: 1.25,
  backfaceVisibility: 'hidden',
};

interface IBContent extends IBaseMeta {
  bottomOffset?: boolean;
  withSplash?: boolean;
  extraImage?: string;
}

export const ExtraSplash = ({
  bottomOffset = false,
  withSplash,
  extraImage,
}: IBContent) => {
  return (
    <div style={boostContainerStyle(bottomOffset)}>
      {withSplash && (
        <img
          src={yellowSplash}
          width={252}
          height={'auto'}
          style={ImgSplashStyle}
          alt="yellow splash"
        />
      )}
      <img
        src={extraImage ?? extra}
        width={200}
        height={100}
        style={ImgBoostStyle}
        alt="extra"
      />
    </div>
  );
};
