import { createDerivedMaterial } from 'troika-three-utils';
import * as THREE from 'three';

export function createInstancedBillboardMaterial(baseMaterial: THREE.Material) {
  return createDerivedMaterial(baseMaterial, {
    vertexMainOutro: `
    vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(0.0, 0.0, 0.0, 1.0);
    vec3 scale = vec3(
      length(instanceMatrix[0].xyz),
      length(instanceMatrix[1].xyz),
      length(instanceMatrix[2].xyz)
    );
    mvPosition.xyz += (modelViewMatrix * vec4(position * scale, 0.0)).xyz;
    gl_Position = projectionMatrix * mvPosition;
`,
  }) as THREE.Material;
}
