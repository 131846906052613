import { motion, MotionProps } from 'framer-motion';
import { background } from './styles';
import backgroundBoostTen from '../../../assets/images/backgrounds/background_boost_ten.svg';

interface IBoostBackground extends MotionProps {
  boost?: number;
}

const BoostBackground = ({
  boost,
  ...props
}: IBoostBackground): React.ReactElement => {
  if (!boost || boost !== 10) {
    return <></>;
  }

  return (
    <motion.img
      width={'413px'}
      height={'206px'}
      src={backgroundBoostTen}
      {...props}
      style={{
        ...background,
        ...props.style,
        willChange: 'clip-path, transform',
        backfaceVisibility: 'hidden',
      }}
    />
  );
};

export default BoostBackground;
