import React from 'react';

export const contentContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

export const boostImageContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flex: 1,
};

export const textContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'opacity, scale',
};

export const wheelContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'opacity, scale',
};

export const wheel: React.CSSProperties = {
  width: 555,
  height: 555,
};

export const wheelImage: React.CSSProperties = {
  display: 'flex',
  position: 'absolute',
  width: 555,
  height: 555,
};

export const wheelCaret: React.CSSProperties = {
  display: 'flex',
  position: 'absolute',
  left: '41%',
  top: '-8%',
  width: 100,
};

export const wheelCentralContainer: React.CSSProperties = {
  display: 'flex',
  position: 'absolute',
  left: '0%',
  top: '0%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 240 * 2.3,
  height: 240 * 2.3,
  borderRadius: '50%',
  backgroundColor: 'white',
  willChange: 'scale',
};

export const wheelBoostLogo: React.CSSProperties = {
  zIndex: 4,
  backfaceVisibility: 'hidden',
};

export const wheelBoostNumber: React.CSSProperties = {
  display: 'flex',
  padding: '45px 42px',
  paddingTop: '50px',
  fontWeight: 800,
  fontFamily: 'Nunito',
  fontSize: 95,
  lineHeight: '32px',
  backgroundColor: 'red',
  color: 'white',
  borderRadius: 60,
  marginTop: -52,
};
