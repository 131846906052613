import { type LanguageStrings } from '../interfaces/api';
import { type Texts } from '../interfaces/sceneState';

export const parseBoolean = (value: string): boolean => {
  return value === 'true';
};

/**
 * Replace the contents of the specified source value with the specified values
 *
 * @param value - The source value
 * @param parameters - The parameters to replace
 */
export function stringReplace(
  value: string,
  parameters: Record<string, string | number> = {},
): string {
  return Object.keys(parameters).reduce(
    (output, parameter) =>
      output.replace(
        new RegExp(`{${parameter}}`, 'g'),
        parameters[parameter].toString(),
      ),
    value,
  );
}

/**
 * Pad a value to the specified length adding the specified character to the left of the existing
 * text
 *
 * @param value - The source value
 * @param length - The length to pad the value to
 * @param char - The character to pad the source value with
 */
export function padLeft(
  value: string | number = '',
  length: number = String(value).length,
  char: string = ' ',
): string {
  return Array.apply(null, new Array(length))
    .map(() => char)
    .concat(value.toString().split(''))
    .splice(-length)
    .join('');
}

/**
 * Pad a value to the specified length adding the specified character to the right of the existing
 * text
 *
 * @param value - The source value
 * @param length - The length to pad the value to
 * @param char - The character to pad the source value with
 */
export function padRight(
  value: string | number = '',
  length: number = String(value).length,
  char: string = '  ',
): string {
  return value
    .toString()
    .split('')
    .concat(Array.apply(null, new Array(length)).map(() => char))
    .slice(0, length)
    .join('');
}

/**
 * Generate a GUID
 */
export function generateGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}

/**
 *
 * @param input The string to format
 * @param components The components to replace
 */
export function templateString(
  input: string,
  components: Record<string, string | number>,
): string {
  return Object.keys(components).reduce(
    (out, key) =>
      // eslint-disable-next-line no-useless-escape
      out.replace(new RegExp(`\\\[${key}\\\]`, 'g'), String(components[key])),
    input,
  );
}

/**
 *
 * @param input The LanguageStrings to format
 * @param components The components to replace
 * @deprecated use templateTexts
 */
export function templateLanguageStrings(
  input: LanguageStrings,
  components: Record<string, string | number>,
): LanguageStrings {
  return input.map((languageString) => ({
    ...languageString,
    text: templateString(languageString.text, components),
  }));
}

export function templateTexts(
  input: Texts,
  components: Record<string, string | number>,
): Texts {
  return input.map((languageString) => ({
    ...languageString,
    text: templateString(languageString.text, components),
  }));
}
