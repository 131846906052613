import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../../constants/colours';
import { Text } from '../../../../models/text';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../../helpers/arrays';
import LayoutService from '../../../../services/layout';
import { type ISceneMeshesBase, Scene } from '../../../../models/scene';
import { BallShaded } from '../../../../models/ballShaded';
import { ColorPlane } from '../../../../models/colorPlane';
import { ImagePlane } from '../../../../models/imagePlane';
import {
  SceneState,
  SceneType,
  type DrawStateScene,
} from '../../../../interfaces/sceneState';
import { type ILoExScene } from '../../../../interfaces/scene';
import { FeatureFlag } from '../../../../enums/featureFlag';
import { ISettings } from '../../../../interfaces/app';
import { sendMessage } from '../../../../helpers/message';

const BALL_TIME_DIFF = 5000;
interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallShaded[];
  cover?: ColorPlane;
  logo?: ImagePlane;
  drawInProgress?: Text;
}

export default class DrawScene2024 extends Scene implements ILoExScene {
  private _channel: 'TV' | 'ONLINE' = 'TV';
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state!: DrawStateScene;
  protected _language!: string;
  protected _featureFlags!: FeatureFlag[];

  protected _meshes: ISceneMeshes = {
    balls: [],
  };

  private _isSorted: boolean = false;
  private _ballInterval: NodeJS.Timeout | null = null;
  private _ballPool: BallShaded[] = [];
  private _isReorganizing: boolean = false;

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: SceneState<{}>,
    settings: ISettings,
  ): Promise<void> => {
    this._channel = settings.channel;
    this._state = state as DrawStateScene;
    this._language = language;
    this._featureFlags = settings.featureFlags ?? [];

    if (this._channel === 'TV' && this._state.scene !== SceneType.Reorder) {
      return;
    }

    const { _meshes: meshes, _scene: scene } = this;

    this._isSorted = this._state.scene === SceneType.Reorder;
    scene.add((meshes.cover = new ColorPlane()));

    try {
      await meshes.cover.configure({
        color: COLORS.white,
        state: {
          position: [0, 0, -3.4],
          rotation: [0, 0, 0],
          scale: [8.92, 5.0175, 1],
          opacity: 0,
        },
      });
    } catch (error) {
      console.log('Error in setup:', error);
    }
  };

  public play = async (): Promise<void> => {
    if (this._channel === 'TV' && this._state.scene !== SceneType.Reorder) {
      return;
    }

    const {
      _state: { scene },
    } = this;
    const isReorderScene = scene === SceneType.Reorder;
    // wait 200 ms
    await new Promise((resolve) => setTimeout(resolve, 200));
    try {
      if (isReorderScene) {
        await this.synchroniseBalls();
      } else {
        await this.ballAnimationWithSmartPooling();
      }
    } catch (error) {
      console.log('Error in play:', error);
    }
  };

  public onChange = async (state: SceneState<{}>): Promise<void> => {
    if (this._channel === 'TV' && state.scene !== SceneType.Reorder) {
      return;
    }

    this._state = state as DrawStateScene;
    const { scene } = state;

    const isReorderScene = scene === SceneType.Reorder;

    try {
      if (!this._isSorted && isReorderScene) {
        this._isSorted = true;
        await this.reorganiseBalls(false, true);
      } else if (this._isSorted && !isReorderScene) {
        this._isSorted = false;
        await this.reorganiseBalls(true, false);
      }
    } catch (error) {
      console.log('Error in onChange:', error);
    }
  };

  public teardown = async (): Promise<void> => {
    if (this._ballInterval) {
      clearInterval(this._ballInterval);
      this._ballInterval = null;
    }

    if (this._channel === 'TV' && this._state.scene !== SceneType.Reorder) {
      return;
    }

    const { _meshes: meshes } = this;

    if (!Object.keys(meshes).length) {
      await this.destroy();
      return;
    }

    try {
      const tweenPromises = [];

      if (meshes.cover) {
        tweenPromises.push(meshes.cover.tweenTo({ opacity: 1 }, 1000));
      }

      tweenPromises.push(
        ...meshes.balls.map((ball) => ball.tweenOut({ opacity: 0 })),
      );

      await Promise.all(tweenPromises);

      this._meshes.balls.forEach((ball) => this.returnBallToPool(ball));
      this._meshes.balls = [];

      await this.destroy();
    } catch (error) {
      console.log('Error in teardown:', error);
    }
  };

  private synchroniseBalls = async (): Promise<void> => {
    const {
      _meshes: meshes,
      _scene: scene,
      _state: {
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;
    const currentResults = getSortData(balls, (a, b) => a - b);
    const ballLayout = LayoutService.ballGridLayout();

    createArrayOfLength(20).forEach((_, i) => {
      this.addBallToPos(
        ballLayout,
        currentResults,
        i,
        scene,
        meshes.balls,
        currentResults[i].value,
        false,
      );
    });
  };

  private ballAnimationWithSmartPooling = async (): Promise<void> => {
    const {
      _meshes: meshes,
      _scene: scene,
      _state: {
        endTime,
        startTime,
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;
    const totNbBalls = 20;
    const ballIDOffset = 0.7;
    const ballLayout = LayoutService.ballGridLayout();

    const { initDeltaTime, progress } = computeDelta(startTime);

    const nbOfBalls = Math.floor(
      (initDeltaTime + BALL_TIME_DIFF) / BALL_TIME_DIFF - ballIDOffset,
    );
    const currentResults = getSortData(balls, (a, b) => a - b);

    let ballID = Math.max(nbOfBalls, 0);
    const animatedBalls = new Set();
    createArrayOfLength(nbOfBalls).forEach((_, i) => {
      animatedBalls.add(i);
      this.addBallToPos(
        ballLayout,
        currentResults,
        i,
        scene,
        meshes.balls,
        currentResults[i].value,
        false,
      );
    });

    if (this._ballInterval) {
      clearInterval(this._ballInterval);
    }

    if (nbOfBalls < totNbBalls) {
      const animateBall = () => {
        if (ballID >= totNbBalls) {
          if (this._ballInterval) clearInterval(this._ballInterval);
          return;
        }
        this.addBallToPos(
          ballLayout,
          currentResults,
          ballID,
          scene,
          meshes.balls,
          currentResults[ballID].value,
          true,
          document.visibilityState === 'visible',
        );
        ballID++;
      };

      const ballAnimationLoop = async () => {
        const { initDeltaTime } = computeDelta(startTime);

        const nbOfBalls = Math.floor(
          (initDeltaTime + BALL_TIME_DIFF) / BALL_TIME_DIFF - ballIDOffset,
        );
        const ballID = Math.max(nbOfBalls, 0);
        for (let i = 0; i < ballID; i++) {
          if (!animatedBalls.has(i)) {
            animatedBalls.add(i);
            animateBall();
          }
        }
        if (nbOfBalls < totNbBalls) {
          requestAnimationFrame(ballAnimationLoop);
        }
      };
      requestAnimationFrame(ballAnimationLoop);
    }

    new Promise<void>((resolve) => {
      setTimeout(() => resolve(), endTime - progress);
    });
  };

  private async reorganiseBalls(from: boolean, to: boolean): Promise<void> {
    if (this._isReorganizing) {
      return;
    }

    this._isReorganizing = true;

    try {
      const {
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);
      const ballLayout = LayoutService.ballGridLayout();

      // check delta time, if delta time bigger than the whole ball animation time, then skip the animation
      const skipAnimation = async () => {
        if (document.visibilityState !== 'visible') {
          await this.instantAnimateBallsIn(from, currentResults, ballLayout);
          this._isReorganizing = false;
          return true;
        }
        return false;
      };

      if (await skipAnimation()) return;
      await this.animateBallsOut(from, currentResults, ballLayout);
      if (await skipAnimation()) return;
      await this.changeBallPositions(to, currentResults, ballLayout);
      if (await skipAnimation()) return;
      await this.animateBallsIn(to, currentResults, ballLayout);
    } catch (error) {
      console.log('Error in reorganiseBalls:', error);
    } finally {
      this._isReorganizing = false;
    }
  }

  private async animateBallsOut(
    from: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(from);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.tweenTo(
          {
            scale: [0.0001, 0.0001, 0.0001],
            rotation: [0, 0, -Math.PI * 4],
            opacity: 0,
          },
          750,
          ballLayout.animationOffset[currentResults[i]?.originalIndex ?? i],
          TWEEN.Easing.Quadratic.In,
        ),
      ),
    );
  }

  private async changeBallPositions(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.changeTo({
          position: ballLayout.positions[currentResults[i]?.originalIndex ?? i],
          rotation: [0, 0, Math.PI * 4],
        }),
      ),
    );
  }

  private async animateBallsIn(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.tweenTo(
          {
            scale: ballLayout.scale,
            rotation: [0, 0, 0],
            opacity: 1,
          },
          750,
          ballLayout.animationOffset[currentResults[i]?.originalIndex ?? i],
          TWEEN.Easing.Quadratic.Out,
        ),
      ),
    );
  }

  private async instantAnimateBallsIn(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        // directly set the position and opacity to 1
        ball?.changeTo({
          position: ballLayout.positions[currentResults[i]?.originalIndex ?? i],
          opacity: 1,
        }),
      ),
    );
  }

  private getBalls(ordered: boolean): (BallShaded | null)[] {
    const {
      _meshes: meshes,
      _state: {
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;

    if (!meshes.balls || meshes.balls.length === 0) {
      return [];
    }
    if (!Object.keys(meshes).length) {
      return [];
    }

    const currentResults = getSortData(balls, (a, b) => a - b);
    return meshes.balls.reduce((out, curr, i) => {
      out[currentResults[i][ordered ? 'sortedIndex' : 'originalIndex']] = curr;
      return out;
    }, []);
  }

  private getBallFromPool(): BallShaded {
    if (this._ballPool.length > 0) {
      return this._ballPool.pop()!;
    }
    return new BallShaded();
  }

  private returnBallToPool(ball: BallShaded) {
    this._ballPool.push(ball);
  }

  private addBall(
    value: number | undefined,
    i: number,
    position: [number, number, number],
    animate: boolean,
    toAnim: boolean,
  ): BallShaded {
    const ball = this.getBallFromPool();
    const ballLayout = LayoutService.ballGridLayout();
    // check delta time, if delta time bigger than the whole ball animation time, then skip the animation
    const skipAnimation = async () => {
      if (!toAnim) {
        await ball.changeTo({ position, opacity: 1 }, 0);
        return true;
      }
      return false;
    };

    const configureBall = async () => {
      await ball.configure({
        dimensions: { height: 256, width: 256 },
        value,
        state: {
          position: animate
            ? [0, 0, -1.25]
            : adjustArrayValues(position, [0, 0, -1]),
          rotation: [0, 0, 0],
          scale: ballLayout.scale,
          opacity: 0,
        },
        featureFlag: FeatureFlag.Loex2024,
      });
    };

    const animateBallIn = async () => {
      if (animate) {
        if (await skipAnimation()) return;
        await Promise.all([
          ball.tweenTo(
            { position: [0, 0, -1], opacity: 1 },
            1500,
            1000,
            TWEEN.Easing.Quadratic.Out,
          ),
        ]);
        await ball.tweenTo({ position, opacity: 1 }, 1000);
      } else {
        await ball.tweenTo(
          {
            position,
            opacity: 1,
          },
          1000,
          i * 100,
          TWEEN.Easing.Elastic.Out,
        );
      }
    };

    configureBall()
      .then(animateBallIn)
      .then(() => {
        sendMessage('draw:ball', value);
      })
      .catch((error) => console.log('Error in addBall:', error));

    return ball;
  }

  private addBallToPos(
    ballLayout: any,
    currentResults: any[],
    ballID: number,
    scene: THREE.Scene,
    meshesBall: BallShaded[],
    ballValue: number | undefined,
    anim: boolean,
    toAnim: boolean = false,
  ) {
    const position = ballLayout.positions[currentResults[ballID].originalIndex];
    if (meshesBall[ballID]) {
      scene.remove(meshesBall[ballID]);
      this.returnBallToPool(meshesBall[ballID]);
    }
    meshesBall[ballID] = this.addBall(
      ballValue,
      ballID,
      position,
      anim,
      toAnim,
    );
    scene.add(meshesBall[ballID]);
  }
}

function computeDelta(startTime: number) {
  const m5s = 300;
  const now = new Date();
  const totalSeconds = now.getMinutes() * 60 + now.getSeconds();
  const progress = (totalSeconds % m5s) * 1000;
  const initDeltaTime = progress - startTime;
  return { initDeltaTime, progress };
}
