import { getQueryStringParameter } from './browser';
import { parseBoolean } from './strings';

export const sendMessage = (type: string, data: any) => {
  if (!isRunningInIframe()) {
    return;
  }

  const { ios = 'false' } = getQueryStringParameter();

  const targetOrigin = parseBoolean(ios)
    ? process.env.REACT_APP_POSTMESSAGE_IOS_TARGET_ORIGIN
    : process.env.REACT_APP_POSTMESSAGE_TARGET_ORIGIN;

  if (targetOrigin) {
    window.parent.postMessage(
      {
        type,
        data,
      },
      targetOrigin,
    );
  }
};

const isRunningInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
