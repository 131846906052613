import React from 'react';
import { T3D } from '.';
import { NumberText } from './NumberText';
import { getSharedMaterials } from './getSharedMaterials';
import * as THREE from 'three';
export interface BallProps {
  number: number;
  position?: T3D;
  scale?: number;
  transition?: boolean;
  staticPos?: boolean;
  visible?: boolean;
}

const planeGeometry = new THREE.PlaneGeometry(0.8, 0.8);

export const BallWithNumber: React.FC<BallProps> = ({
  number,
  position = [0, 0, 0],
  scale = 1,
  transition = false,
  staticPos = false,
  visible = true,
}) => {
  const { yellowWhiteMaterial } = getSharedMaterials();
  return (
    <group
      position={position}
      scale={scale}
      matrixAutoUpdate={!staticPos}
      visible={visible}
    >
      <mesh
        args={[planeGeometry, yellowWhiteMaterial]}
        matrixAutoUpdate={!staticPos}
      />
      <NumberText number={number} transition={transition} />
    </group>
  );
};

export const BallFullYellow: React.FC<{
  front?: boolean;
  meshRef: React.RefObject<THREE.Mesh>;
}> = ({ front = true, meshRef }) => {
  const { fullYellowMaterial } = getSharedMaterials();

  return (
    <mesh
      ref={meshRef}
      renderOrder={front ? 2 : 0}
      args={[planeGeometry, fullYellowMaterial]}
    />
  );
};
