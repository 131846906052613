import React, { useEffect, useRef } from 'react';

export interface IContent {
  backgroundVideo?: string;
  backgroundImages?: string[];
}

const commonStyles: React.CSSProperties = {
  zIndex: -1,
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  willChange: 'auto',
  transform: 'translate3d(0, 0, 0)',
  backfaceVisibility: 'hidden',
  backgroundColor: 'transparent',
  backgroundImage: 'none',
};

const transparentPoster =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const MediaComponent: React.FC<IContent> = React.memo(
  ({ backgroundVideo, backgroundImages }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      const video = videoRef.current;
      if (video && backgroundVideo) {
        // Start playback with error handling
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(() => {
            video.muted = true;
            video.play().catch(() => {});
          });
        }

        return () => {
          video.pause();
        };
      }
    }, [backgroundVideo]);

    if (backgroundVideo) {
      return (
        <video
          ref={videoRef}
          style={{
            ...commonStyles,
            objectPosition: '50% 50%',
          }}
          src={backgroundVideo}
          muted
          playsInline
          autoPlay
          loop
          preload="auto"
          controls={false}
          poster={transparentPoster}
          disableRemotePlayback
          x-webkit-airplay="deny"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="false"
          webkit-playsinline="true"
          crossOrigin="anonymous"
        />
      );
    }

    if (backgroundImages?.[0]) {
      return (
        <img
          style={commonStyles}
          src={backgroundImages[0]}
          alt="background"
          loading="eager"
          decoding="async"
          crossOrigin="anonymous"
        />
      );
    }

    return null;
  },
);

MediaComponent.displayName = 'MediaComponent';
