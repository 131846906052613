import * as THREE from 'three';
import { CanvasTexture } from '../models/canvasTexture';
import { GradientPlane } from '../models/gradientPlane';
import { type Mesh } from '../models/mesh';
import { type Texture } from '../models/texture';
import { COLORS } from '../constants/colours';
import { type Text, type Texts } from '../interfaces/sceneState';
import ImageService from '../services/image';
import TextureService from '../services/texture';
import VideoService from '../services/video';
import { toNearest } from './maths';

export const availableTextureSizes = [64, 128, 256, 512, 1024, 2048];

export const getBackgroundPlane = async (): Promise<GradientPlane> => {
  const gradientPlane = new GradientPlane();

  gradientPlane.configure({
    dimensions: { height: 128, width: 128 },
    state: {
      position: [0, 0, -3.575],
      scale: [8.92, 5.0175, 1],
      rotation: [0, 0, 0],
      opacity: 1,
    },
    stops: [
      [0, COLORS.bgGradientTop],
      [1, COLORS.bgGradientBottom],
    ],
  });

  return await Promise.resolve(gradientPlane);
};

export const generateLinearGradient = (
  context: CanvasRenderingContext2D,
  stops: Array<[number, string]> = [],
  x1: number = 0,
  y1: number = 0,
  x2: number = 1,
  y2: number = 1,
) => {
  const gradient = context.createLinearGradient(x1, y1, x2, y2);
  stops.forEach((val) => gradient.addColorStop(...val));

  return gradient;
};

export const generateColorTexture = (
  width: number = 1,
  height: number = 1,
  color: string = 'transparent',
): Texture => {
  return TextureService.getTexture(`${color}_${width}_${height}`, () => {
    const canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;
    const context = canvas.getContext('2d')!;

    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);

    return new CanvasTexture(canvas);
  });
};

export const generateGradientTexture = (
  width: number = 32,
  height: number = 32,
  stops: Array<[number, string]> = [],
): Texture => {
  return TextureService.getTexture(
    `${stops.map(([p, c]) => `${p}.${c}`).join('_')}_${width}_${height}`,
    () => {
      const canvas = document.createElement('canvas');
      canvas.height = height;
      canvas.width = width;
      const context = canvas.getContext('2d')!;

      context.fillStyle = generateLinearGradient(
        context,
        stops,
        0,
        0,
        0,
        canvas.height,
      );
      context.fillRect(0, 0, canvas.width, canvas.height);

      return new CanvasTexture(canvas);
    },
  );
};

export const getCanvas = (width: number, height: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = toNearest(width, availableTextureSizes);
  canvas.height = toNearest(height, availableTextureSizes);

  return canvas;
};

export const getCanvasTextureFromUrl = async (
  url: string,
  width?: number,
  height?: number,
): Promise<Texture> =>
  await ImageService.getImage(url).then((image) => {
    return TextureService.getTexture(url, () => {
      const canvas = document.createElement('canvas');
      canvas.width = width || toNearest(image.width, availableTextureSizes);
      canvas.height = height || toNearest(image.height, availableTextureSizes);
      const context = canvas.getContext('2d')!;

      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      image.remove();

      return new CanvasTexture(canvas);
    });
  });

export const getCanvasTextureFromUrls = async (
  urls: string[],
  width?: number,
  height?: number,
): Promise<Texture> =>
  await Promise.all(urls?.map(async (url) => await ImageService?.getImage(url)))
    .then((images) => {
      return TextureService.getTexture(urls.join('_'), () => {
        const canvas = document.createElement('canvas');

        canvas.width =
          width ||
          toNearest(
            Math.max(...images.map((image) => image.width)),
            availableTextureSizes,
          );
        canvas.height =
          height ||
          toNearest(
            Math.max(...images.map((image) => image.height)),
            availableTextureSizes,
          );

        const context = canvas.getContext('2d')!;

        images.forEach((image) => {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          image.remove();
        });

        return new CanvasTexture(canvas);
      });
    })
    .catch(() => {
      console.error(`Failed to load one or more textures`, urls);

      return generateColorTexture(8, 8, COLORS.transparent);
    });

// TODO: This will need moving over to an internal class if it gets used
export const getVideoTextureFromUrl = async (
  url: string,
): Promise<THREE.VideoTexture> =>
  await VideoService.getVideo(url).then(
    (video) =>
      new THREE.VideoTexture(
        video,
        undefined,
        undefined,
        undefined,
        THREE.LinearFilter,
        THREE.LinearFilter,
      ),
  );

export interface IStaggeredTextDrawingInstruction extends Omit<Text, 'text'> {
  text: string[];
}

// export const getAlignment = (alignment: CanvasTextAlign): number => {
//   switch (alignment) {
//     case "center":
//       return this._canvas.width / 2;
//     case "right":
//     case "end":
//       return this._canvas.width;
//     case "left":
//     case "start":
//     default:
//       return 0;
//   }
// };

export const measureText = (
  context: CanvasRenderingContext2D,
  text: string,
  font: string,
  size: number,
  textAlign: CanvasTextAlign = 'left',
  textBaseline: CanvasTextBaseline = 'middle',
) => {
  context.font = `${size}px ${font}`;
  context.textAlign = textAlign;
  context.textBaseline = textBaseline;

  return context.measureText(text).width;
};

export const calculateTextOffsets = (
  context: CanvasRenderingContext2D,
  instructions: Texts,
  textAlign: CanvasTextAlign = 'left',
  textBaseline: CanvasTextBaseline = 'middle',
): number[] =>
  instructions.map(({ font, text, size }) =>
    measureText(context, text, font, size, textAlign, textBaseline),
  );

export const drawMultiPartText = (
  context: CanvasRenderingContext2D,
  instructions: Texts,
  textAlign: CanvasTextAlign = 'left',
  textBaseline: CanvasTextBaseline = 'middle',
  range: [number, number] = [0, instructions.length],
  [xOffset, yOffset]: [number, number] = [0, 0],
): CanvasRenderingContext2D => {
  const offsets = calculateTextOffsets(context, instructions);
  context.textAlign = 'left'; // This is like this on purpose
  context.textBaseline = textBaseline;

  instructions
    .slice(range[0], range[1])
    .forEach(({ color, font, size, text, bold }, i) => {
      context.font = `${bold ? 'semibold' : ''} ${size}px ${font}`;
      context.fillStyle = color || COLORS.white;
      context.fillText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
      context.fillText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
      context.strokeStyle = COLORS.transparent;
      context.lineWidth = 0;

      context.strokeText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
    });

  return context;
};

export const drawMonoSpaceMultiPartText = (
  context: CanvasRenderingContext2D,
  instructions: Texts,
  textAlign: CanvasTextAlign = 'left',
  textBaseline: CanvasTextBaseline = 'middle',
  range: [number, number] = [0, instructions.length],
  [xOffset, yOffset]: [number, number] = [0, 0],
  glyphWidth: number,
): CanvasRenderingContext2D => {
  const offsets = instructions.map(() => glyphWidth);

  context.textAlign = 'center'; // This is like this on purpose
  context.textBaseline = textBaseline;

  instructions
    .slice(range[0], range[1])
    .forEach(({ color, font, size, text }, i) => {
      context.font = `${size}px ${font}`;
      context.fillStyle = color || COLORS.white;
      context.fillText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          glyphWidth / 2 +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
      context.fillText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          glyphWidth / 2 +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
      context.strokeStyle = COLORS.transparent;
      context.lineWidth = 0;
      context.strokeText(
        text,
        getHorizontalTextOffset(textAlign, i, offsets, context.canvas.width) +
          glyphWidth / 2 +
          xOffset,
        getVerticalTextOffset(textBaseline, context.canvas.height) + yOffset,
      );
    });

  return context;
};

export const getHorizontalTextOffset = (
  textAlign: CanvasTextAlign,
  index: number,
  offsets: number[],
  width: number,
): number => {
  switch (textAlign) {
    case 'left':
    case 'start':
      return offsets.slice(0, index).reduce((out, curr) => (out += curr), 0);
    case 'right':
    case 'end':
      return offsets.slice(index).reduce((out, curr) => (out -= curr), width);
    case 'center':
      return (
        offsets.slice(0, index).reduce((out, curr) => (out += curr), 0) +
        (width / 2 - offsets.reduce((out, curr) => (out += curr), 0) / 2)
      );
    default:
      return 0;
  }
};

export const getVerticalTextOffset = (
  textBaseline: CanvasTextBaseline,
  height: number,
): number => {
  switch (textBaseline) {
    case 'top':
    case 'hanging':
      return 0;
    case 'middle':
      return height / 2;
    case 'alphabetic':
    case 'bottom':
      return height;
    default:
      return 0;
  }
};

export const destroyMesh = async (mesh: Mesh) =>
  await new Promise<void>((resolve) => {
    mesh.getMaterials().forEach((m) => {
      if (m instanceof THREE.MeshBasicMaterial) {
        if (m.map instanceof THREE.Texture && !(m.map as Texture).retain) {
          if (
            m.map instanceof THREE.CanvasTexture &&
            m.map.image instanceof HTMLCanvasElement
          ) {
            m.map.image.remove();
            m.map.image = null;
          }

          m.map.dispose();
          m.map = null;
        }

        m.dispose();
      }
    });

    mesh.geometry.dispose();

    return resolve();
  });
