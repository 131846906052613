import * as THREE from 'three';
import { createDerivedMaterial } from 'troika-three-utils';

// This material allows to make plane that are 2d and face the screen,
// it allow big optimization for object that don't need to rotate in depth
export function createBillboardMaterial(baseMaterial: THREE.Material) {
  return createDerivedMaterial(baseMaterial, {
    vertexMainOutro: `
        vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
        vec3 scale = vec3(
          length(modelViewMatrix[0].xyz),
          length(modelViewMatrix[1].xyz),
          length(modelViewMatrix[2].xyz)
        );
        mvPosition.xyz += position * scale;
        gl_Position = projectionMatrix * mvPosition;
        `,
  });
}

export function createBillboardMaterialT(baseMaterial: THREE.Material) {
  baseMaterial.onBeforeCompile = (shader: { vertexShader: string }) => {
    // Modify the vertex shader by inserting billboard transformation logic at the right place
    shader.vertexShader = shader.vertexShader.replace(
      '#include <project_vertex>',
      `
        // Billboard effect logic
        vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );

        vec3 scale = vec3(
          length(modelViewMatrix[0].xyz),
          length(modelViewMatrix[1].xyz),
          length(modelViewMatrix[2].xyz)
        );

        mvPosition.xyz += position * scale;
        gl_Position = projectionMatrix * mvPosition;
      `,
    );
  };

  return baseMaterial;
}
