import * as THREE from 'three';
import ballYellowWhite from '../../../../assets/yellow_ball.svg';
import ballRedWhite from '../../../../assets/red_ball.svg';
import ballFullYellow from '../../../../assets/full_ball.svg';
import ballAsset from '../../../../assets/grid_ball.svg';
import {
  createBillboardMaterial,
  createBillboardMaterialT,
} from './shaders/billboardMaterial';
import { createInstancedBillboardMaterial } from './shaders/instancedBillboardMaterial';

let yellowWhiteMaterial: THREE.Material | null = null;
let rotatableExtraMaterial: THREE.Material | null = null;
let fullYellowMaterial: THREE.Material | null = null;
let instancedGridBillboardMaterial: THREE.Material | null = null;
let textBillboardMaterial: THREE.Material | null = null;
let instancedGridFinalMaterial: THREE.Material | null = null;

export const getSharedMaterials = () => {
  if (
    !yellowWhiteMaterial ||
    !fullYellowMaterial ||
    !instancedGridBillboardMaterial ||
    !textBillboardMaterial ||
    !instancedGridFinalMaterial
  ) {
    const loader = new THREE.TextureLoader();
    const yellowWhiteTexture = loader.load(ballYellowWhite);
    const redWhiteTexture = loader.load(ballRedWhite);
    const fullYellowTexture = loader.load(ballFullYellow);
    const instancedGridTexture = loader.load(ballAsset);

    textBillboardMaterial = createBillboardMaterialT(
      new THREE.MeshBasicMaterial({ transparent: true, side: THREE.FrontSide }),
    );

    yellowWhiteMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: yellowWhiteTexture,
        side: THREE.FrontSide,
      }),
    );

    fullYellowMaterial = createBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: fullYellowTexture,
        side: THREE.FrontSide,
      }),
    );

    rotatableExtraMaterial = new THREE.MeshBasicMaterial({
      alphaTest: 0.5,
      map: redWhiteTexture,
      side: THREE.FrontSide,
    });

    instancedGridFinalMaterial = new THREE.MeshBasicMaterial({
      alphaTest: 0.5,
      map: yellowWhiteTexture as THREE.Texture,
      side: THREE.FrontSide,
    });

    instancedGridBillboardMaterial = createInstancedBillboardMaterial(
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: instancedGridTexture as THREE.Texture,
        side: THREE.FrontSide,
      }),
    );
  }

  return {
    yellowWhiteMaterial,
    rotatableExtraMaterial,
    fullYellowMaterial,
    instancedGridBillboardMaterial,
    instancedGridFinalMaterial,
    textBillboardMaterial,
  } as {
    yellowWhiteMaterial: THREE.Material;
    rotatableExtraMaterial: THREE.Material;
    fullYellowMaterial: THREE.Material;
    instancedGridBillboardMaterial: THREE.Material;
    instancedGridFinalMaterial: THREE.Material;
    textBillboardMaterial: THREE.Material;
  };
};
