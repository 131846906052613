import React, {
  startTransition,
  Suspense,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Canvas } from '@react-three/fiber';
import { motion, MotionStyle } from 'framer-motion';
import { SceneType } from '../../interfaces/sceneState';
import { IStateProps } from '.';
import { SCENE_COMPONENTS, EmptyScene } from './SceneManager';
import { useHandleResize } from './hooks/useHandleResize';
import { useVisibilityChange } from './hooks/useVisibilityChange';
import { canvasAndDivContStyle, canvasStyle, htmlContStyle } from './styles';
import backgroundDefault from './../../assets/images/backgrounds/background_blue_halo.svg';

const FADE_DURATION = 0.5;
const FADE_TIMEOUT = (FADE_DURATION - 0.05) * 1000;
const FADE_IN_DURATION = 0.9;

export const APP_WIDTH = 1280;
export const APP_HEIGHT = 720;
export const screenRatio = APP_HEIGHT / APP_WIDTH;
export const Loex2024Canvas: React.FC<IStateProps> = React.memo((props) => {
  const { scene = SceneType.Loading, backgroundImage = backgroundDefault } =
    props?.currentState || {};
  const { skipAnim, handleExitComplete } = useVisibilityChange(scene);
  const scale = useHandleResize();
  const [delayedScene, setDelayedScene] = useState(scene);
  const propsRef = useRef(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const propsDelayed = useMemo(() => {
    return delayedScene
      ? JSON.parse(JSON.stringify(propsRef.current))
      : propsRef.current;
  }, [delayedScene]);

  useDelayedRender(scene, setDelayedScene);

  const motionProps = useMemo(() => {
    const syncScene = delayedScene === scene;
    const isFadeIn = syncScene && !skipAnim.current;
    return {
      initial: {
        opacity: skipAnim.current ? 1 : 0,
        transform: 'scale(0.99)',
      },
      animate: {
        opacity: isFadeIn ? 1 : 0,
        transform: isFadeIn ? 'scale(1)' : 'scale(0.99)',
      },

      transition: {
        duration: skipAnim.current
          ? 0
          : syncScene
            ? FADE_IN_DURATION
            : FADE_DURATION,
        ease: 'easeInOut',
      },
      style: canvasAndDivContStyle as MotionStyle,
    };
  }, [skipAnim, delayedScene, scene]);

  const canvasProps = useMemo(
    () => ({
      linear: true,
      flat: true,
      style: canvasStyle(backgroundImage),
      gl: {
        antialias: false,
        precision: 'lowp',
      },
      shadows: false,
      dpr: Math.max(window.devicePixelRatio * 0.9, 0.9),
    }),
    [backgroundImage],
  );

  const DelayedSceneComponent = useMemo(
    () => SCENE_COMPONENTS[delayedScene] || EmptyScene,
    [delayedScene],
  );

  return (
    <>
      <div
        id="canvas-container"
        style={{
          ...canvasAndDivContStyle,
          width: `${APP_WIDTH * scale}px`,
          height: `${APP_HEIGHT * scale}px`,
        }}
      >
        <motion.div onAnimationComplete={handleExitComplete} {...motionProps}>
          <Canvas {...canvasProps}>
            <ambientLight intensity={4} />
            <Suspense fallback={null}>
              <DelayedSceneComponent isHtml={false} {...propsDelayed} />
            </Suspense>
          </Canvas>
        </motion.div>
      </div>
      <div
        id="html-container"
        style={{
          ...htmlContStyle,
          transform: `scale(${scale})`,
        }}
      >
        <motion.div onAnimationComplete={handleExitComplete} {...motionProps}>
          <DelayedSceneComponent isHtml={true} {...propsDelayed} />
        </motion.div>
      </div>
    </>
  );
});

function useDelayedRender(
  scene: SceneType,
  setDelayedScene: (scene: SceneType) => void,
) {
  const fadeTimeoutRef = useRef(null);
  const currentSceneRef = useRef(scene);

  useEffect(() => {
    if (currentSceneRef.current === scene) return;
    currentSceneRef.current = scene;
    if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);

    fadeTimeoutRef.current = setTimeout(() => {
      startTransition(() => setDelayedScene(scene));
    }, FADE_TIMEOUT);

    return () => {
      if (fadeTimeoutRef.current) clearTimeout(fadeTimeoutRef.current);
    };
  }, [scene, setDelayedScene]);
}
