import { Text } from '@react-three/drei';
import { T3D } from '.';
import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import font3D from '../../../../assets/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf';
import { getSharedMaterials } from './getSharedMaterials';
import { preloadFont } from 'troika-three-text';

const TextZOffset = 0.00055;
interface INumberTextProps {
  position?: T3D;
  rotation?: T3D;
  scale?: number;
  number: number | string;
  transition?: boolean;
  rotatable?: boolean;
  material?: THREE.Material;
}

export interface INumberTextHandle {
  updateMatrix: (position?: T3D, rotation?: T3D) => void;
}

export const NumberText = forwardRef<INumberTextHandle, INumberTextProps>(
  (
    {
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      scale = 1,
      number,
      transition = true,
      rotatable = false,
      material,
    }: INumberTextProps,
    ref,
  ) => {
    const textRef = useRef<THREE.Mesh>(null!);
    useFadeTransition(transition, textRef);
    const updateMatrix = useCallback(
      (
        positions = [position[0], position[1], position[2]] as T3D,
        rotations = rotation as T3D,
      ) => {
        if (textRef.current) {
          textRef.current.matrixAutoUpdate = false;
          textRef.current.position.set(...positions);
          textRef.current.rotation.set(...rotations);
          textRef.current.translateZ(TextZOffset);
          textRef.current.updateMatrix();
        }
      },
      [textRef, position, rotation],
    );

    useEffect(() => updateMatrix(), [updateMatrix]);
    useImperativeHandle(ref, () => ({ updateMatrix }));
    const { textBillboardMaterial } = getSharedMaterials();

    return (
      <Text
        ref={textRef}
        position={position}
        rotation={rotation}
        fontSize={0.38 * scale}
        color="black"
        anchorX="center"
        anchorY="middle"
        font={font3D}
        characters={number.toString()}
        material={material ?? (rotatable ? undefined : textBillboardMaterial)}
        renderOrder={rotatable ? undefined : 1}
      >
        {number}
      </Text>
    );
  },
);

// preload the font and text troika
preloadFont({ font: font3D, characters: '0123456789' }, () => {});

export function useFadeTransition(
  transition: boolean,
  ref: MutableRefObject<any>,
) {
  useEffect(() => {
    if (transition && ref.current && ref.current.material) {
      ref.current.material.transparent = true;
      ref.current.material.opacity = 0;
    }
  }, [transition, ref]);

  useFrame((_state, delta) => {
    if (transition && ref.current && ref.current.material) {
      ref.current.material.opacity = Math.min(
        ref.current.material.opacity + delta,
        1,
      );
    }
  });
}
