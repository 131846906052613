import React from 'react';
import { FONTS } from '../../../../enums/fonts';

export const JackpotContainer: React.CSSProperties = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  position: 'absolute',
  top: '170px',
  right: '910px',
  textAlign: 'right',
};

export const JackpotSubContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  left: '390px',
  bottom: '385px',
};

export const gameLogo: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '400px',
  padding: '25px 40px',
  backfaceVisibility: 'hidden',
};
